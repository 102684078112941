import * as React from 'react';
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export default function MyApp() {
  return (
    <Container maxWidth="lg">

    <Stack direction="column" spacing={6}>
      <Stack direction="row" py={2} justifyContent={{xs: 'center', sm: 'left'}}>
        <Box component="img" width={{ xs: '100px', sm: "200px"}} height="auto" src="/kreedlogo-new-2.png"
             alt="Kreed" loading="lazy" itemProp="logo"
             sizes="(max-width: 800px) 100vw, 800px" />
      </Stack>
      <Stack py={6}>
        <Box component="div" sx={{ color: '#ffffff', fontSize: '22px', textAlign: {xs: 'center', sm: 'left'}}}>
          Due to major interruptions in our supply chain, we have suspended sales and services until these issue are resolved.
        </Box>
      </Stack>
    </Stack>
    </Container>
  );
}
